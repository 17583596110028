import { Card, CardActionArea, CardActions, CardContent, CardMedia, makeStyles, Typography, Button, IconButton } from '@material-ui/core'
import React from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => ({
    media: {
        maxHeight: '20rem'
    },
    root: {
        marginTop: '2rem'
    },
    km: {
        alignSelf: 'flex-end'
    },
    openInGoogle: {
        alignSelf: 'flex-start'
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    delete: {
        alignSelf: 'flex-end',
    },
    user: {
        alignSelf: 'flex-start',
    }
}))

export default function ZoneCard(props) {

    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    src={props.ImgUrl}
                    className={classes.media}
                    component='img'
                />
                <CardContent>
                    <Typography variant="h3" >{props.title}</Typography>
                    <Typography variant="body2" >{props.description}</Typography>
                </CardContent>
            </CardActionArea>
            <CardActions className={classes.actions}>
                <Button className={classes.openInGoogle} href={`https://www.google.com/maps/search/?api=1&query=${props.lat}, ${props.lon}`} >Open in Google Maps</Button>
                {props.isOwn ? (
                    <IconButton className={classes.delete} onClick={props.deleteZone}>
                        <DeleteForeverIcon color="secondary" />
                    </IconButton>
                ) : (<></>)}
                <Typography className={classes.user} vairant="body2">posted by: {props.username}</Typography>
                <Typography className={classes.km} variant="body2">{props.distance}km away</Typography>
            </CardActions>
        </Card>
    )
}
