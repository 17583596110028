import { makeStyles, Paper, Grid, Typography, SvgIcon } from '@material-ui/core'
import React from 'react'
import { ReactComponent as Logo } from "../CZLOGO.svg";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        margin: 0,
    },
    paper: {
        width: '40vw',
        padding: '3rem',
    },
    title: {
        marginBottom: '2rem'
    },
    Logo: {
        fontSize: '15rem',
        margin: '2rem'
    }
}))

export default function LogIn(props) {
    const classes = useStyles();

    return (
        <Grid className={classes.root}>
                <SvgIcon className={classes.Logo}>
                    <Logo />
                </SvgIcon>
                {props.children}
        </Grid>
    )
}
