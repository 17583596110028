import './App.css';
import { createContext, useState, useEffect } from 'react';

//Firebase imports
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/storage';
import 'firebase/auth'

import * as geofirex from 'geofirex'

//importing useAuthState from react-firebase-hooks
import { useAuthState } from 'react-firebase-hooks/auth'

//importing Material-Ui components
import { TextField, Button, Divider } from '@material-ui/core';

//importing own Files
import Login from './pages/LogIn'
import Dashboard from './pages/Dashboard'

var firebaseConfig = {
  apiKey: "AIzaSyCZpzDASFCMHjj22XWzOCKx97brhN_e6nQ",
  authDomain: "chill-zone-988e5.firebaseapp.com",
  databaseURL: "https://chill-zone-988e5.firebaseio.com",
  projectId: "chill-zone-988e5",
  storageBucket: "chill-zone-988e5.appspot.com",
  messagingSenderId: "363894571803",
  appId: "1:363894571803:web:871b529412f57af025c9be",
  measurementId: "G-F0QJPCB5ST"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Initializing Geofirex
const geo = geofirex.init(firebase)

const db = firebase.firestore();
const auth = firebase.auth();
//Setting up a Context for Firebase and User OBJ
export const MainContext = createContext()

function App() {
  const [user] = useAuthState(auth)
  const [currentLocation, setcurrentLocation] = useState({})
  const [GPSON, setGPSON] = useState(false)

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' })
      .then(function (permissionStatus) {
        if (permissionStatus.state == 'granted') {
          setGPSON(true)
        } else {
          setGPSON(false)
        }
        permissionStatus.onchange = function () {
          if (permissionStatus.state == 'granted') {
            setGPSON(true)
          } else {
            setGPSON(false)
          }
        };
      });
  }, navigator.permissions)

  useEffect(() => {
    if ('geolocation' in navigator) {
      setGPSON(true)
      navigator.geolocation.watchPosition(function (position) {
        setcurrentLocation(position.coords)
      });
    } else {
      setGPSON(false);
    }
  }, GPSON)

  return (
    <MainContext.Provider value={{ firebase, geo, db, user, GPSON, currentLocation }}>
      <div className="App">

        {GPSON ? (user ?
          <Dashboard logout={() => auth.signOut()} />
          :
          <Login title="Chill Zone">
            <SignInWithGoogle />
          </Login>
        ) : (<>In order to use our App, you need to enable GPS.</>)
        }
      </div>
    </MainContext.Provider>
  );
}

const SignInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  const SignIn = () => {
    auth.signInWithPopup(provider).catch(function (error) {
      console.log(error.message);
    })
  }

  return (
    <Button
      color="default"
      variant="contained"
      style={{ marginTop: '1rem', width: '40%' }}
      onClick={() => { SignIn() }}
      fullWidth
    >
      Sign In With Google
    </Button>
  )
}

const SignInEmailPassword = () => {
  const [Mail, setMail] = useState('');
  const [Password, setPassword] = useState('')

  const SignIn = () => {
    auth.signInWithEmailAndPassword(Mail, Password).catch(function (error) {
      console.log(error.message);
    })
  }

  return (
    <>
      <TextField
        type="email"
        placeholder="share@chill.zone"
        onChange={(event) => { setMail(event.target.value) }}
        variant="outlined"
        fullWidth
      />
      <TextField
        type="password"
        placeholder="supersecret"
        onChange={(event) => { setPassword(event.target.value) }}
        variant="outlined"
        fullWidth
      />
      <Button
        color="primary"
        variant="contained"
        onClick={() => { SignIn() }}
        style={{ marginTop: '1rem', marginBottom: '0.8rem', width: '95%' }}
      >
        Sign In
        </Button>
      <Divider variant="middle" />
    </>
  )
}

export default App;
