import { AppBar, Grid, makeStyles, Toolbar, Typography, SvgIcon, Icon, IconButton } from '@material-ui/core'
import React from 'react'
import { ReactComponent as Logo } from "../CZLOGO.svg";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

//import own scripts
import AddLocation from '../components/AddLocation'
import Feed from '../components/Feed';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    root: {
        flexGrow: 1,
    },
    drawer: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        height: '100vh',
    },
    Logo: {
        fontSize: '4rem',
        margin: '0.5rem'
    }
}))

export default function Dashboard(props) {

    const classes = useStyles();

    return (
        <Grid className={classes.root} container>
            <AppBar position="sticky">
                <Toolbar>
                    <Icon className={classes.Logo} >
                        <Logo/>
                    </Icon>
                    <Typography variant="h3" className={classes.title}>Chill Zone</Typography>
                    <AddLocation />
                    <IconButton onClick={() => props.logout()}>
                        <ExitToAppOutlinedIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>


            <Grid item container>
                <Grid item xs={12}>
                    
                </Grid>
                <Grid item xs={1} >
                    {/* <Drawer
                        variant="permanent"
                    >
                        <AddLocation />
                    </Drawer> */}
                </Grid>
                <Grid item xs={10}>
                    <Feed />
                </Grid>
                <Grid item xs={1} />
            </Grid>
        </Grid>
    )
}
