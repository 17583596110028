import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { MainContext } from '../App'
import ZoneCard from './ZoneCard';

export default function Feed() {

    const { firebase, geo, db, user } = useContext(MainContext);
    const [currentCoords, setcurrentCoords] = useState({})
    const [Zones, setZones] = useState();

    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const zones = geo.query('zones')
                setcurrentCoords(position.coords);
                const geoPoint = geo.point(position.coords.latitude, position.coords.longitude)
                const radius = 100;

                const query = zones.within(geoPoint, radius, 'location')
                query.subscribe((data) => {
                    setZones(data);
                    console.log(data);
                });
            })
        }
    }, [])

    const calcdistance = (coords) => {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(coords.latitude - currentCoords.latitude);  // deg2rad below
        var dLon = deg2rad(coords.longitude - currentCoords.longitude);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(currentCoords.latitude)) * Math.cos(deg2rad(coords.latitude)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

    const deg2rad = (deg) => {
        return deg * (Math.PI / 180)
    }

    const deleteZone = (id, filename) => {
        firebase.storage().ref().child(`images/${filename}`).delete().then(() => {
            db.collection('zones').doc(id).delete().then(() => {
                //Deleted
            }).catch((error) => {
                console.log(error.message);
            })
        }).catch((error) => {
            console.log(error.message);
        })
    }

    return (
        <div>
            {Zones ? (
                <>
                    {Zones.map(zone => (
                        <ZoneCard
                            key={zone.uid}
                            isOwn={user.uid === zone.user.uid}
                            username={zone.user.name}
                            deleteZone={() => { deleteZone(zone.id, zone.filename) }}
                            title={zone.title}
                            description={zone.description}
                            lat={zone.location.geopoint.latitude}
                            lon={zone.location.geopoint.longitude}
                            distance={calcdistance(zone.location.geopoint)}
                            ImgUrl={zone.url}
                        />
                    ))
                    }
                </>
            ) : (
                    <CircularProgress />
                )}
        </div>
    )
}
