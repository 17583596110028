import { Grid, Paper, Typography, TextField, makeStyles, Input, Switch, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core'
import AddLocationIcon from '@material-ui/icons/AddLocationOutlined'
import { get } from 'geofirex';
import React, { useContext, useState } from 'react'
import { MainContext } from '../App';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '2rem',
    },
    TextField: {
        marginTop: '1rem'
    }
}))

export default function AddLocation(event) {
    const classes = useStyles();

    //Setting up States for all Inputs
    const [Title, setTitle] = useState('');
    const [Description, setDescription] = useState('')
    const [File, setFile] = useState()
    const [UseDeviceLocation, setUseDeviceLocation] = useState(true)
    const [Latitude, setLatitude] = useState()
    const [Longitude, setLongitude] = useState()

    const [DialogOpen, setDialogOpen] = useState(false)

    //Importing DB Reference from MainContext
    const { db, geo, firebase, user, currentLocation } = useContext(MainContext)

    const validateInput = () => {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

        if (!acceptedImageTypes.includes(File['type']))
            return false

        if (!UseDeviceLocation)
            if (Latitude == undefined || Longitude == undefined)
                return false

        return true
    }

    const AddLocation = async (event) => {
        event.preventDefault();

        if (!validateInput())
            return //Alert something is wrong with your Input

        const path = `${Date.now()}_${File.name}`
        //Uploading Image
        firebase.storage().ref().child(`images/${path}`).put(File).then(async function (snapshot) {

            let geoPoint = {};
            //Getting the location
            switch (UseDeviceLocation) {
                case true:
                    geoPoint = geo.point(currentLocation.latitude, currentLocation.longitude);
                    break;
                case false:
                    geoPoint = geo.point(Latitude, Longitude);
                    break;
            }

            //Getting the Download Url for the Picture
            const downloadURL = await snapshot.ref.getDownloadURL();

            //Adding a document to the zones collection with all data
            db.collection('zones').add({
                title: Title,
                description: Description,
                url: downloadURL,
                filename: path,
                location: geoPoint,
                user: {
                    uid: user.uid,
                    name: user.displayName,
                }
            }).then(() => {
                setDialogOpen(false);
            }).catch(error => {
                console.log(error.message)
                //Delete the Picture from Firbase Storage
            })
        })
    }

    return (
        <>
            <IconButton onClick={() => { setDialogOpen(true); }} edge="end"><AddLocationIcon fontSize="large" htmlColor="#ffffff" /></IconButton>

            <Dialog open={DialogOpen}>
                <DialogTitle><Typography variant="h4">Add a Chill Zone</Typography></DialogTitle>
                <form onSubmit={(event) => { AddLocation(event) }}>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label="Name of the Chillzone"
                            placeholder="Parkbench"
                            onChange={(event) => { setTitle(event.target.value) }}
                            className={classes.TextField}
                            fullWidth
                            required
                        />
                        <TextField
                            variant="outlined"
                            label="Give us a brief description"
                            placeholder="Beautiful parkbench with a lovely view of Hamburgs haven."
                            onChange={(event) => { setDescription(event.target.value) }}
                            className={classes.TextField}
                            multiline
                            fullWidth
                            required
                        />
                        <Typography variant="h4" className={classes.TextField} >Add a Photo.</Typography>
                        <Input
                            type="file"
                            onChange={(event) => { setFile(event.target.files[0]) }}
                            required
                        />

                        <Typography variant="h4" className={classes.TextField} >Where is your Chillzone?</Typography>
                        <Typography variant="h5" className={classes.TextField} >Use Device Location? </Typography>
                        <Switch
                            checked={UseDeviceLocation}
                            onChange={() => { setUseDeviceLocation(!UseDeviceLocation) }}
                            color="primary"
                            name="useDeviceLocation"
                        />

                        {UseDeviceLocation ? (<></>) : (
                            <div>
                                <TextField
                                    variant="outlined"
                                    label="latitude"

                                    onChange={(event) => { setLatitude(event.target.value) }}
                                    placeholder="53.2345"
                                />
                                <TextField
                                    variant="outlined"
                                    label="longitude"

                                    onChange={(event) => { setLongitude(event.target.value) }}
                                    placeholder="53.2345"
                                />
                            </div>
                        )}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => { setDialogOpen(false) }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Publish
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}
